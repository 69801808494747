var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('basic-container',[_c('avue-crud',{ref:"crud",attrs:{"option":_vm.option,"table-loading":_vm.loading,"data":_vm.data,"page":_vm.page,"permission":_vm.permissionList},on:{"update:page":function($event){_vm.page=$event},"search-change":_vm.searchChange,"search-reset":_vm.searchReset,"selection-change":_vm.selectionChange,"current-change":_vm.currentChange,"size-change":_vm.sizeChange,"refresh-change":_vm.refreshChange,"on-load":_vm.onLoad},scopedSlots:_vm._u([{key:"menu",fn:function(scope){return [(_vm.permission.flow_manager_state)?_c('el-button',{staticClass:"none-border",attrs:{"type":"text","size":"small","plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleState(scope.row,scope.index)}}},[_vm._v("变更状态 ")]):_vm._e(),(_vm.permission.flow_manager_image)?_c('el-button',{staticClass:"none-border",attrs:{"type":"text","size":"small","plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleImage(scope.row,scope.index)}}},[_vm._v("流程图 ")]):_vm._e(),(_vm.permission.flow_manager_remove)?_c('el-button',{staticClass:"none-border",attrs:{"type":"text","size":"small","plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleSlotDelete(scope.row,scope.index)}}},[_vm._v("删除 ")]):_vm._e()]}},{key:"tenantId",fn:function(ref){
var row = ref.row;
return [_c('el-tag',[_vm._v(_vm._s(row.tenantId===''?'通用':row.tenantId))])]}},{key:"version",fn:function(ref){
var row = ref.row;
return [_c('el-tag',[_vm._v("v"+_vm._s(row.version))])]}},{key:"suspensionState",fn:function(ref){
var row = ref.row;
return [_c('el-tag',[_vm._v(_vm._s(row.suspensionState===1?'激活':'挂起'))])]}},{key:"category",fn:function(ref){
var row = ref.row;
return [_c('el-tag',[_vm._v(_vm._s(row.categoryName))])]}}]),model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('template',{slot:"menuLeft"},[_c('el-radio-group',{attrs:{"size":"small"},model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}},[_c('el-radio-button',{attrs:{"label":"1"}},[_vm._v("通用流程")]),_c('el-radio-button',{attrs:{"label":"2"}},[_vm._v("定制流程")])],1)],1)],2),_c('el-dialog',{attrs:{"title":"流程图","append-to-body":"","visible":_vm.flowBox,"fullscreen":true},on:{"update:visible":function($event){_vm.flowBox=$event}}},[_c('iframe',{attrs:{"src":_vm.flowUrl,"width":"100%","height":"700","title":"流程图","frameBorder":"no","border":"0","marginWidth":"0","marginHeight":"0","scrolling":"no","allowTransparency":"yes"}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.flowBox = false}}},[_vm._v("关 闭")])],1)]),_c('el-dialog',{attrs:{"title":"流程变更","append-to-body":"","visible":_vm.stateBox,"width":"20%"},on:{"update:visible":function($event){_vm.stateBox=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"流程状态"}},[_c('el-select',{attrs:{"placeholder":"请选择","value":""},model:{value:(_vm.flowState),callback:function ($$v) {_vm.flowState=$$v},expression:"flowState"}},_vm._l((_vm.stateOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.stateBox = false}}},[_vm._v("关 闭")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleDoState}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }