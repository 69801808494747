<template>
  <div>
    <avue-form v-model="user" :option="option" @submit="submit">
      <template slot="userType" slot-scope="{item}">
        <span>{{ item }}</span>
      </template>
    </avue-form>
    <avue-crud :option="option2"
               :table-loading="loading"
               :data="data"
               @row-del="rowDel"
               @on-load="onLoad"
               ref="crud">
    </avue-crud>
  </div>
</template>

<script>
import {getList,insert,delRow} from '@/api/pay/userPermissions'
  export default {
    name:'userPermissions',
    props: ['itemId','itemName','isOpen2','payeeId','departId'],
    data(){
      return{
        user:{
          userId:'',
          userId2:'',
          userName:'',
          payeeName:'',
          departId:'',
          departName:'',
          payeeId:'',
          itemName:'',
          itemId:'',
          state:'',
          id:''
        },
        data:[],
        loading:true,
        form:{},
        option:{
          width:500,
          column:[
            {
              label:'用户',
              prop: 'userId',
              type: 'select',
              span:20,
              props: {
                label: 'userName',
                value: 'id'
              },
              remote:true,
              dicUrl: `/api/blade-user/userPage?phone={{key}}`,
              typeformat(item) {
                //console.log('item='+`${item['realName']}//`)
                return `${item['deptName']}/${item['realName']}`
              },
              rules: [
                {
                  required: true,
                  message: '请输入用户',
                  trigger: 'blur'
                }
              ]
            }
          ]
        },
        option2:{
          page:false,
          align:'center',
          addBtn:false,
          editBtn:false,
          delBtn: true,
          column:[
            {
              label:'姓名',
              prop:'userName',
              addDisplay: false,
              editDisplay: false
            },
            {
              label:'手机号',
              prop:'phone',
              addDisplay: false,
              editDisplay: false
            },
            {
              label:'科室',
              prop:'deptName',
              addDisplay: false,
              editDisplay: false
            },
          ]
        }
      }
    },
    methods:{
      onLoad(){
        this.loading = true
        getList({itemId:this.itemId}).then((res)=>{
          console.log(res)
          if(res.data.code === 200){
            this.data = res.data.data
            this.loading = false
          }else{
            this.$message.error(res.data.msg)
          }
        })
      },
      submit(from,done){
        insert({userId:from.userId,itemId:this.itemId,departId:this.departId,payeeId:this.payeeId}).then((res)=>{
          if(res.data.code === 200){
            this.$message.success('操作成功！')
            this.onLoad()
          }else{
            this.$message.error(res.data.msg)
          }
          done()
        })
      },
      rowDel(row){
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            delRow({userId:row.userId,itemId:this.itemId}).then((res)=>{
              if(res.data.code === 200){
                this.$message.success('操作成功！')
                this.onLoad()
              }else{3
                this.$message.error(res.data.msg)
              }
            })
          })
      }
    },
    mounted(){
      this.onLoad()
    },
    watch: {
      'itemId':{
        handler(val){
          // console.log("val===",val);
          // this.money = 0
          this.onLoad()
        },
        immediate: true
      }
    }
  }
</script>
