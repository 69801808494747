import request from '@/router/axios';

// 获取表格数据 列表
export const getList = (current, size, params) => {
  return request({
    url: '/api/zt-pay/payItems/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 新增接口
export const add = (row) => {
  return request({
    url: '/api/zt-pay/payItems/insert',
    method: 'post',
    data: row
  })
}

// 编辑
export const update = (row) => {
  return request({
    url: '/api/zt-pay/payItems/update',
    method: 'post',
    data: row
  })
}


export const  payExport = (params) => {
  return request({
    url: '/api/zt-pay/payItems/export',
    method: 'get',
    params: {
      ...params
    },
    responseType: 'blob'
  })
}

