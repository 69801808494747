<template>
    <div class="deptPowerWarper">
        <div>{{$route.query.payeeName}} - {{$route.query.departName}} 收款部门权限组开通</div>
        
        <div class="powerAdd" v-if="$route.query.classId === ''">
            <avue-input-tree ref="tree" default-expand-all v-model="form" placeholder="请选择内容" type="tree" :dic="dic"></avue-input-tree>
            <el-button  class="addBtn" type="primary" @click="addPower">添 加</el-button>
        </div>
        <div class="smallT">已开通权限组：</div>
        <div class="powerTable">
            <avue-crud  :data="data" ref="crud" :option="option" :table-loading="loading" @row-del="rowDel">
                <template slot-scope="{type,size,row}" slot="menu">
                    <el-button  :type="type" icon="el-icon-view" size="small"  
                    @click="powerCheck(row)">查看权限组人员</el-button>
                </template>
            </avue-crud>
        </div>
    </div>
</template>
<script>
import request from '@/router/axios';
export default {
    name: 'deptPower',
    data(){
        return {
            form:'',
            dic:[],
            data: [],
            loading: false,
            option:{
            addBtn: false,
            page:false,
            align:'center',
            menuAlign:'center',
            viewBtn: false,
            delBtn: true,
            classId: this.$route.query.classId,
            delBtnText:'删除权限组',
            // viewBtnText:'查看权限组人员',
            editBtn: false,
            column:[
                {
                    label:'集团／公司',
                    prop:'parentName'
                },
                {
                    label:'部门',
                    prop:'deptName'
                }
            ]
            }
        }
    },
    mounted(){
        // /dept/list
        this.getDeptPowerList()
        // let classId = this.$route.query.classId
        // this.classId = classId
        // alert(this.classId)
        let classId = sessionStorage.getItem("classId"); 
        // alert(classId)
        // this.classId = classId
        if (classId !== '' && classId !== undefined){
            this.onLoad(classId)
        }
        
    },
    methods: {
        onLoad(departId) {
            this.data = []
            this.loading = true;
            request({
                url: '/api/blade-system/dept/detail',
                method: 'get',
                port: '8016',
                params:{ id: departId}
            }).then((res)=>{
                // console.log(res.data.data)
                let resp = res.data.data
                this.data.push(resp)
                this.loading = false;
            })
        },
        // 点击添加按钮
        addPower(){
            // console.log(this.form)
            let departId = ''
            let queryDeptId = this.$route.query.departId
            if (queryDeptId !== undefined && queryDeptId !== '') {
                departId = queryDeptId
            }
            this.postPower(departId, this.form) // 部门id classId
            this.onLoad(this.form)
        },
        // 请求添加/编辑/删除 接口
        postPower(id, classId){
            request({
                url: 'api/zt-pay/department/updateDepartment',
                method: 'post',
                data:{ id: id, classId: classId}
            }).then((res)=>{
                // console.log(res)
                // this.classId = classId
                sessionStorage.setItem("classId", classId);
                if(res.success){
                    this.$message({
                        type: "success",
                        message: "操作成功!"
                    });
                }
            })
        },
        // 获取下拉权限的所有数据
        getDeptPowerList(){
            request({
                url: 'api/blade-system/dept/list',
                method: 'get',
                port: '8016',
            }).then((res)=>{
                let resp = res.data.data
                // 把数组对象转成字符串,全局匹配修改key名，然后再把字符串转成json对象
                var arrStr = JSON.stringify(resp).replace(/deptName/g, "label").replace(/id/g, "value");
                this.dic = JSON.parse(arrStr)
            })
        },
        // 点击删除权限组按钮
        rowDel(row, index, done) {
            this.$confirm("确定将选择数据删除?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
            .then(() => {
                // return remove(row.id);
                let id = this.$route.query.departId !== '' ? this.$route.query.departId : ''
                this.postPower(id, '')
            })
            .then(() => {
                this.$message({
                type: "success",
                message: "操作成功!"
                });
                done(row);
            });
        },
        powerCheck(row){
            this.$router.push({ path: '/system/user'})
        }
    },
    watch:{
    //   form:{
    //      handler() {
    //       setTimeout(()=>{
    //         console.log(this.$refs.tree)
    //         this.form33=this.$refs.tree.getHalfList();
    //         console.log(this.form33)
    //       },0)
    //     },
    //     immediate: true,  
    //   }
    }
}
</script>
<style scoped>
    .deptPowerWarper{ background: #ffffff; padding: 20px;}
    .avue-input-tree{
        width: 60%;
    }
    .powerAdd{
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .powerTable{
        margin-top: 20px;
    }
    .addBtn{ margin-left: 20px; min-width: 60px; padding: 12px 30px;}
    .smallT{ font-size: 14px; color: #666;}
</style>