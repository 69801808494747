<template>
  <basic-container>测试页</basic-container>
</template>

<script>
  export default {};
</script>

<style>
</style>
