<template>
  <div>
    <div>项目：{{itemName}}</div>
    <avue-form  :option="option" v-model="form" @submit="submit" @reset-change="resetChange">
    </avue-form>
    <avue-form  :option="optionPay" v-model="formPay">
      <template slot="payLink">
        <div>
          <el-input style="width: 70%; margin-right: 20px" v-model="formPay.payLink" placeholder="支付链接"></el-input>
          <el-button type="" @click="copyLink">复制</el-button>
        </div>
      </template>
      <template slot="text">
        <div>
          <img v-if="imgSrc !== '' && imgSrc !== undefined " style="witdh: 200px; height: 200px;" 
            border="0" :src="'data:image/png;base64,' + imgSrc"/>
          <el-button type="primary" @click="downLoadImg">下载二维码</el-button>
          <!-- <el-input v-model="form.text" placeholder="这里是自定的表单"></el-input> -->

          <div>
          
        </div>
        </div>
      </template>
    </avue-form>
  </div>
</template>

<script>
import { getList, update } from "@/api/pay/payShare";
export default {
   name:'payShare',
   props: ['itemId','itemName','isOpen'],
   data() {
      return {
        type: 0,
        imgSrc:'',
        form: {
          // title:'我是头部标题',
          url: '',
          appId: '',
          secretKey: '',
        },
        formPay: {
          payLink: '',
          payImg: ''
        },
        obj: {
          title:'我是头部标题',
        },
        option: {
          detail:false,
          group: [
            {
              label: '接入配置',
              prop: 'jbxx',
              icon: 'el-icon-edit-outline',
              column: [
                {
                  label: 'URL',
                  prop: 'url',
                  // detail:true,
                  row: true,
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: '请输入URL',
                  //     trigger: 'blur'
                  //   }
                  // ]
                },{
                  label: 'APP_ID',
                  prop: 'appId',
                  row: true,
                  disabled: true,
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: '请输入APP_ID',
                  //     trigger: 'blur'
                  //   }
                  // ]
                },
                {
                  label: '密钥',
                  prop: 'secretKey',
                  type:'textarea',
                  showWordLimit:true,
                  disabled: true,
                  row: true,
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: '请输入密钥',
                  //     trigger: 'blur'
                  //   }
                  // ]
                }
              ]
            }
          ]
        },
        optionPay: {
          menuBtn: false,
          group: [
            {
              label: '支付码和链接',
              prop: 'tksq',
              icon: 'el-icon-view',
              column: [
                {
                  label: '支付链接',
                  prop: 'payLink',
                  row: true,
                  formslot: true,
                },
                {
                  label: '支付二维码',
                  prop: 'text',
                  // type: 'load',
                  formslot:true
                  // span: 24,
                },
              ]
            }
          ]
        }
      }
    },
    methods:{
      getInfo(){
        let param = {itemId: this.itemId}
        getList(param).then(res => {
          let data = res.data.data
          this.form.url = data.url
          this.form.appId = data.app_id
          this.form.secretKey = data.secret_key
          this.imgSrc = data.qrCode
          this.formPay.payLink = data.pay_link            
        });
      },
      submit(form,done) {
          let param = {...form, itemId: this.itemId}
          update(param).then(res => {
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            console.log(res)
            done()        
          });
      }, 
      resetChange(){
        this.form.url = ''
      },
      // 复制支付链接
      copyLink(){
        this.$Clipboard({
          text: this.formPay.payLink
        }).then(() => {
          this.$message.success('复制成功')
        }).catch(() => {
          this.$message.error('复制失败')
        });
      },
      
      // 下载二维码
      downLoadImg(){
        
        let base64 = this.imgSrc;
        let raw = window.atob(base64)
        let rawLength = raw.length;
        let uInt8Array = new Uint8Array(rawLength);
        for(let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        const blob =  new Blob([uInt8Array], {
            type: "image/png"
        })
        let aLink = document.createElement('a');
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        aLink.download = "支付二维码";
        aLink.href = URL.createObjectURL(blob);
        aLink.click()
      }
    },
    watch: {
      'itemId':{
        handler(val){
          // console.log("val===",val);
            // this.money = 0
          this.getInfo()
        },
        immediate: true
    }
    }
    
}
</script>

<style>
  .head{
    width: 100%;
  }
  .avue-view{
    background-color: white;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .avue-main{
    background-color: white;
  }
  .navigation{
    width: 97%;
    margin: 0 auto;
    font-size: 20px;
  }
  .PageContent{
    width: 92%;
    margin: 4% auto;
  }
  .PageTitle{
    font-size: 25px;
    color: #b3b3c1;
  }
  .ProjectName{
    font-size: 25px;
    margin-top: 20px;
  }
  .TitleItem{
    font-size: 20px;
    margin-top: 20px;
  }
  .seg{
    width: 80%;
    height: 1px;
    background-color: black;
    margin-top: 20px;
  }
  .LeftTitle{
    width: 7%;
    float: left;
    margin-top: 30px;
    font-size: 20px;
  }
  .RightContent{
    width: 55%;
    float: left;
    margin-top: 30px;
    font-size: 20px;
  }
  .left_title{
    height: 80px;
    line-height: 80px;
    color: #b3b3c1;
  }
  .right_content{
    height: 80px;
    line-height: 80px;
  }
  .right_input{
    width: 90%;
    padding-left: 5px;
    height: 40px;
    line-height: 40px;
    background-color: #f2f2f2;
  }
  .ConfirmBtn{
    background-color: white;
    width: 200px;
    height: 50px;
    letter-spacing: 5px;
  }
  .CopyBtn{
    margin-left: 10px;
    width: 150px;
    height: 45px;
    background-color: #f2f2f2;
  }
  .QR_Boder{
    width: 160px;
    height: 160px;
    border: 1px solid black;
    margin-top: 30px;
    float: left;
  }
  .DownloadBtn{
    margin-left: 10px;
    width: 150px;
    margin-top: 150px;
    height: 40px;
    background-color: #f2f2f2;
  }
</style>
