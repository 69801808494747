import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/zt-pay/payee/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const savePayee = (row) => {
  return request({
    url: '/api/zt-pay/payee/savePayee',
    method: 'post',
    data: row
  })
}

export const updatePayee = (row) => {
  return request({
    url: '/api/zt-pay/payee/updatePayee',
    method: 'post',
    data: row
  })
}

export const getPayeeList = () => {
  return request({
    url: '/api/zt-pay/payee/updatePayee',
    method: 'post',
  })
}
