<template>
  <basic-container>
  <avue-crud :option="option"
             :data="data"
             :page.sync="page"
             :key = "data.departId"
              ref="crud"
             @row-save="rowSave"
             @row-update="rowUpdate"
             @on-load="onLoad"
             @search-change="searchChange"
             @search-reset="searchReset">
    <!--<template slot-scope="{type,size}" slot="menu">-->
      <!--<el-button type="danger"-->
                 <!--size="small"-->
                 <!--icon="el-icon-delete"-->
                 <!--v-if="permission.role_delete"-->
                 <!--plain-->
                 <!--@click="">删 除-->
      <!--</el-button>-->
    <!--</template>-->
  </avue-crud>
  </basic-container>
</template>
<style>

</style>
<script>
  import {mapGetters} from "vuex";
  import {getList, savePayee, updatePayee} from "@/api/pay/payee";
  const defaultQuery = {
    payeeName: '',
    departName: '',
    state: ''
  }
  export default {
    data(){
      return{
        form:{},
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        data:[],
        query: {},
        loading:true,
        option: {
          tip: false,
          simplePage: true,
          searchShow: true,
          page:true,
          searchMenuSpan: 6,
          tree: true,
          border: true,
          delBtn: false,
          index: true,
          selection: true,
          viewBtn: true,
          dialogWidth: 900,
          dialogClickModal: false,
          viewTitle:'详情',
          column: [
            {
              label:'收款方',
              prop:'payeeName',
              search: true,
              span: 24,
              rules: [
                {
                  required: true,
                  message: "请输入收款方",
                  trigger: "blur"
                }
              ]
              // dicData: []
            },
            {
              label:'收款部门',
              // display: false,
              prop:'departName',
              search: true,
              // dicUrl: "/api/zt-pay/department/list",
              // type: "select",
              // showColumn:false,
              addDisplay: false,
              editDisplay: false,
              rules: [
                {
                  required: true,
                  message: "请输入收款方",
                  trigger: "blur"
                }
              ]
              // props: {
              //   label: "departName",
              //   value: "id"
              // },
            },
            {
              label: "支付项目数",
              prop: "items",
              // type: "tree",
              span: 24,
              addDisplay: false,
              editDisplay: false
            },
            {
              label: "交易笔数",
              prop: "orders",
              //type: "tree",
              span: 24,
              addDisplay: false,
              editDisplay: false,
            },
            {
              label: "状态",
              prop: "state",
              type: "radio",
              dicData: [
                {
                  label: "正常",
                  value: 1
                },
                {
                  label: "关闭",
                  value: 0
                }
              ],
              rules: [
                {
                  required: true,
                  message: "请选择状态",
                  trigger: "blur"
                }
              ]
            },
          ]
        },
      }
    },
    computed:{
      //...mapGetters(["userInfo", "permission"]),
      // permissionList() {
      //   return {
      //     addBtn: this.vaildData(this.permission.payee_add,false),
      //     viewBtn: this.vaildData(this.permission.payee_view,false),
      //     delBtn: this.vaildData(this.permission.payee_delete,false),
      //     editBtn: this.vaildData(this.permission.payee_edit,false),
      //   };
      // },
    },
    methods: {
      onLoad(page, params = {}) {
        //this.loading = true;
        getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
          const data = res.data.data;
          console.log(data)
          this.page.total = data.total;
          this.data = data.records;
          //this.loading = false;
          //this.selectionClear();
        });
      },
      rowUpdate(row, index, done, loading) {
        console.log(row)
        let param = {
          payeeName: '',
          payeeId: '',
          departName: '',
          state: '',
          id: ''
        }
        param.payeeName = row.payeeName
        param.payeeId = row.payeeName
        param.state = row.state
        param.id = row.payeeId
        updatePayee(param).then(() => {
          this.onLoad(this.page,defaultQuery);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          window.console.log(error);
          loading();
        });
      },
      rowDel() {
        return false
      },
      rowSave(row, done, loading) {//新增
        savePayee(row).then((res) => {
          // 获取新增数据的相关字段
          const data = res.data.data;
          console.log(data)
          row.id = data.id;
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          // 数据回调进行刷新
          done(row);
        }, error => {
          window.console.log(error);
          loading();
        });
      },
      searchReset(){
        this.query = JSON.parse(JSON.stringify(defaultQuery))
      },
      searchChange(params, done) {
        this.query = params;
        this.page.currentPage = 1;
        this.onLoad(this.page, params);
        done();
      },
      refreshChange() {
        this.$message({
          message: `当前的返回的分页对象${JSON.stringify(this.page)} 搜索表单对象${JSON.stringify(this.search)}`,
          type: 'success',
        });
      }
    }
  }
</script>
