import request from '@/router/axios';

export const getList = (params) => {
  return request({
    url: '/api/zt-pay/userPermissions/list',
    method: 'get',
    params: params
  })
}

export const insert = (row) => {
  return request({
    url: '/api/zt-pay/userPermissions/insert',
    method: 'post',
    data: row
  })
}

export const delRow = (row) => {
  return request({
    url: '/api/zt-pay/userPermissions/delete',
    method: 'post',
    data: row
  })
}
